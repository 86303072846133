require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

$('.corporations-slick').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    dots: false,
    arrows: true,

    responsive: [
    {
        breakpoint: 1366,
        settings: {
        slidesToShow: 3,
        }
    },
    {
        breakpoint: 768,
        settings: {
        slidesToShow: 1,
        arrows: false,
        }
    }]
});
